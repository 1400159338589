import { createSelector } from 'reselect';
import { difference, filter, flow, get, intersection, isNumber, keys, map, pick, size } from 'lodash/fp';
import getParentProductId from '@ahmdigital/logic/lib/utils/get-parent-product-id';

import constants from '../../ahm-constants';
import getSaleableProductIds from '../../utils/get-saleable-product-ids';
import logger from '../../logging';
import selectCart from '../select-cart';
import selectProducts from '../select-products';

/**
 * Select saleable hospital excess for cart hospital/package product.
 *
 * @param {object} cart - Cart from state.
 * @param {object} products - Products from state.
 * @returns {number[]} Returns an array of saleable hospital excess.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
const getExcessToggleValues = (cart, products) => {
  if (cart.baseProductType === constants.PRODUCT_TYPE.EXTRAS) {
    return [];
  }
  // if you're on a product page your cart is auto-populated with the current product's ids
  const productId = get('hospital.id')(cart) || get('package.id')(cart);
  if (!productId) {
    // select all excess options by default as we have no restrictions and exit early e.g. for product comparer
    // can't use compact because it rejects '0' as falsey
    return filter(isNumber, [0, 500, 750]);
  }

  // Because of Choosable #ART-881, we can have a product in the cart but not in the product state - in this instance we
  // won't know valid excess for the product until they navigate back to a hospital/package details/buy page
  if (!get(productId)(products)) {
    return [];
  }

  // Find all saleable products with the same parent product id, and then get all excess amounts for the product group
  const parentProductId = getParentProductId(productId);

  // @ts-expect-error - Automatic, Please fix when editing this file
  const saleableProductIds = intersection(getSaleableProductIds(), get(parentProductId)(constants.PRODUCTS_BY_PARENT));
  const saleableProducts = pick(saleableProductIds)(products);

  // e.g 750 product in our cart, PRODUCTS_BY_PARENT shows 500 & 750, but 500 isn't in product state
  // This should never be the case, but if this happens we should investigate why a product failed to load alt. excess
  // this can be cleaned up if we don't see this behaviour
  if (saleableProductIds.length !== size(saleableProducts)) {
    logger.getLogger().error('Unable to load all excess. Missing expected product in state', {
      cartProduct: productId,
      expectedSaleableProductIds: saleableProductIds,
      missingProducts: difference(saleableProductIds, keys(saleableProducts)),
    });
  }

  // if the products haven't loaded into state they'll have undefined amounts, so we should not show them yet
  const hospitalExcess = flow(map('charges.excess.amount'), filter(isNumber))(saleableProducts).sort();

  return hospitalExcess;
};

const selectExcessToggleValues = createSelector([selectCart, selectProducts], getExcessToggleValues);

export default selectExcessToggleValues;

export type ExcessToggleValues = number[];

export { getExcessToggleValues };
