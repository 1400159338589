import { includes, keys, pick, values } from 'lodash/fp';

import constants from '../../ahm-constants';

const { CUSTOMER_NEEDS, PRODUCTS_BY_NEEDS, PRODUCT_TYPE } = constants;

// @ts-expect-error - Automatic, Please fix when editing this file
const getIsExtrasComparerPage = ({ needsProductType, needsType }) => {
  if (!(needsProductType && needsType)) {
    return false;
  }
  const applicableNeedsProductType = keys(PRODUCTS_BY_NEEDS[CUSTOMER_NEEDS.EXTRAS_COVER]);
  const hasApplicableNeedsProductType =
    needsType === CUSTOMER_NEEDS.EXTRAS_COVER && includes(needsProductType, applicableNeedsProductType);
  const hasApplicableNeedsType =
    needsProductType === PRODUCT_TYPE.EXTRAS &&
    includes(needsType, values(pick(['BARE_BONES', 'FAMILY'], CUSTOMER_NEEDS)));
  return hasApplicableNeedsType || hasApplicableNeedsProductType;
};

export default getIsExtrasComparerPage;
